import * as React from "react";
import { PortableText } from "@portabletext/react";
import { AppWrapper } from "../components/AppWrapper";
import { Helmet } from "react-helmet";

const Blog = ({ pageContext }) => {
	return (
		<AppWrapper>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{pageContext.title} — Andy Gallagher</title>
			</Helmet>

			<div>
				<strong>
					<header>
						(<a href="/">home</a>)
					</header>
				</strong>
				<main>
					<h2
						css={`
							margin-top: 16px;
							line-height: 1;
						`}
					>
						{pageContext.title}
					</h2>
					<p>
						<i>published at:</i>{" "}
						{new Date(pageContext.publishedAt).toDateString()}
					</p>

					<hr />
					<PortableText
						value={pageContext._rawBody}
						// components={components}
					/>
				</main>

				<strong>
					<footer>
						(<a href="/">home</a>)
					</footer>
				</strong>
			</div>
		</AppWrapper>
	);
};

export default Blog;
